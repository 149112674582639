import React, {useReducer} from 'react'
import { githubContext } from './githubContext'
import {githubReducer} from './githubReducer'
import { SEARCH_USERS, GET_USER, GET_REPOS, CLEAR_USERS, SET_LOADING } from '../types'
import axios from 'axios'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const withCreds = url => {
    return `${url}client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`
}

export const GithubState = ({children}) => {
    const initialState = {
        user: {},
        users: [],
        repos: [],
        loading: false
    }

    const [state, dispatch] = useReducer(githubReducer, initialState)

    const search = async value => {
        setLoading()
        
        const responce = await axios.get(
            withCreds(`https://api.github.com/search/users?q=${value}&`)
        )

        dispatch({
            type: SEARCH_USERS,
            payload: responce.data.items
        })
    }

    const getUser = async name => {
        setLoading()
        
        const responce = await axios.get(
            withCreds(`https://api.github.com/user/${name}?`)
        )

        dispatch({
            type: GET_USER,
            payload: responce.data
        })
    }

    const getRepos = async name => {
        setLoading()
        
        const responce = await axios.get(
            withCreds(`https://api.github.com/users/${name}/repos?per_pages=5&`)
        )

        dispatch({
            type: GET_REPOS,
            payload: responce.data
        })
    }
    
    const clearUsers = () => dispatch({type: CLEAR_USERS})
    const setLoading = () => dispatch({type: SET_LOADING})

    const {user, users, repos, loading} = state

    return (
        <githubContext.Provider value={{search,getUser,getRepos,clearUsers,setLoading,user, users, repos, loading}}>
            {children}
        </githubContext.Provider>
    )
}