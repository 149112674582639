import React from 'react'
import {Link} from 'react-router-dom'

export const Card = ({user}) => (
    <div className="card mt-4 mb-4">
        <img src={user.avatar_url} className="card-img-top" alt={user.login} />
        <div class="card-body">
            <h5 className="card-title">{user.login}</h5>
            <Link to={'/profile/'+user.login} className="btn btn-primary">More</Link>
        </div>
    </div>
)