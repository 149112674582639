import React from 'react'

export const About = () => {

    return (
        <div class="card mt-5">
            <h5 class="card-header">About</h5>
            <div class="card-body">
                <h5 class="card-title">App version 1.0.0</h5>
            </div>
        </div>
    )
}