import React, {useContext, useEffect, Fragment} from 'react'
import { githubContext } from '../context/github/githubContext'
import { Link, useParams } from 'react-router-dom'

export const Profile = () => {
    const params = useParams()
    // console.log(params)

    const {getUser, getRepos, loading, user} = useContext(githubContext)
    const urlName = params.name

    useEffect(() => {
        getUser(urlName)
        getRepos(urlName)
    },[])
    
    // console.log(user)

    if(loading) { return <p>loading...</p>  }

    return (
        <Fragment>
            <Link to="/" className="btn btn-link">Home page</Link>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-3 text-center">
                            <img src={user.avatar_url} alt={user.name} />
                            <h1>{user.name}</h1>
                            { user.location && <p>location: {user.location}</p> }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}