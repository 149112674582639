import React, {Fragment, useContext} from 'react'
import {Search} from '../components/Search'
import {Card} from '../components/Card'
import {Alert} from '../components/Alert'
import { githubContext } from '../context/github/githubContext'

export const Home = () => {

    const {loading, users} = useContext(githubContext)

    return (
        <Fragment>
            <Alert alert={{text:'test alert'}} />
            <Search />
            <div className="row">
                {
                    loading
                        ? <p>loading...</p>
                        : users.map(
                            user => (
                                <div className="col-12 col-sm-4" key={user.id}><Card user={user} /></div>
                            )
                        )
                }
            </div>
        </Fragment>
    )
}