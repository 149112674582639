import React, {useContext, useState} from 'react'
import { alertContext } from '../context/alert/alertContext'
import { githubContext } from '../context/github/githubContext'

export const Search = () => {
    const [value, setState] = useState('')
    const alert = useContext(alertContext)
    const github = useContext(githubContext)

    const onSubmit = event => {
        if(event.key !== 'Enter') {
            return
        }
        github.clearUsers()
        if(value.trim()){
            alert.hide()
            github.search(value.trim())
        }else{
            alert.show('input is empty')
        }
    }

    return (
        <div className="form-group mb-3">
            <input
                type="text"
                className="form-control"
                placeholder="Enter name"
                value={value}
                onChange={event => setState(event.target.value)}
                onKeyPress={onSubmit}
             />
        </div>
    )
}